@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logosize {
  width: 200px;
}

body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ol,
ul {
  padding: 0 !important;
}
.classnumber {
  font-size: 17px !important;
  font-weight: bold;
  color: #000 !important;
}

.yellowfb800d {
  color: #fb8110;
  font-size: larger;
}

.top-header-section {
  padding-top: 1em;
  padding-bottom: 1em;
}
.container_view {
  width: 90%;
  margin: 0 auto;
}
.container_view .headerSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.contact_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.callsection {
  display: flex;
  flex-direction: row;
  justify-items: start;
  align-items: center;
  margin: 0 0.5em;
}
.callsection::after {
  content: "";
  width: 1px;
  height: 50px;
  background: #707274;
  margin: 0 15px;
}
.callus .text-left1 {
  text-align: left !important;
}
.phoneImg .phonewidth {
  font-size: 30px;
  color: #fb8110;
}
.gapivvu {
  margin-top: 2em !important;
  width: 100% !important;
}
.bookappointment .radiusBtn {
  background: #fb8110 !important;
  border-radius: 20px !important;
  color: #000 !important;
  font-weight: bold;
}
.bgshap {
  background-color: #7D2280;
  padding: 5px;
}
.crls {
  color: #fb8110;
}
.yrsexp {
  font-size: 20px;
}
.blueclr {
  color: #7D2280;
}

#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  background: #fff;
}

#header.header-fixed {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  line-height: 0;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #635551;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

#main {
  z-index: 3;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  color: #635551;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #ff5821;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #ff5821;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #ff6b3b;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #ff5821;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #473d3a;
  font-size: 28px;
  cursor: pointer;
  display: block;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(43, 37, 35, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #473d3a;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #ff5821;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #ff5821;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.carousel-caption{
  color:#000 !important
}



.drdetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drbanner {
  background-image: url(../src/assets/drbanner.png);
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: cover;
  /* max-width: 100%;
  height: 50vh; */
}

.hspbanner {
  background-image: url(../src/assets/drbanner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: 50vh;
}
.welcomeCls {
  color: #7D2280 !important;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: -10;
  left: 0;
  right: 0;
}

#hero .container {
  z-index: 2;
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  color: #fff;
}
.small10 {
  font-size: 0.6em;
}

#hero h2 {
  color: #eee;
  margin-bottom: 10px;
  font-size: 13px;
}
.madamdetails {
  width: 100%;
  flex: left;
}

#hero .get-started-icon {
  font-size: 24px;
  background: #7D2280;
  padding: 14px;
  color: #fff;
  border-radius: 50px;
  position: relative;
  z-index: 5;
  box-shadow: 10px 2px 15px rgba(0, 0, 0, 0.1);
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  transition: 0.5s;
  margin-left: -10px;
  padding: 8px 26px 8px 26px;
  color: #fff;
  background: #7D2280;
  border-radius: 0 50px 50px 0;
  position: relative;
  z-index: 4;
}

#hero .btn-get-started:hover {
  background: #ff5821;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 1024px), (max-height: 768px) {

  #main1{
    display: block;
  }
  #hero {
    margin-bottom: -150px;
    height: 80vh;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  #c_time{
    display: block;
  }

  #c_date{
    display: block;
  }
}

@media (max-height: 640px) {
  #hero {
    width: 100%;
    height: 100vh;
    background: url("../src/assets/hero-bg1.jpg") center center;
    background-size: cover;
    position: relative;
    top: -9px;
    z-index: 1;
    padding: 0;
    margin-bottom: -120px;
  }
  .flexdear {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .topspace {
    position: relative;
    top: -95px;
  }
  .container_view .headerSection {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
  }
  .flexdear {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .topspace {
    position: relative;
    top: 16px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: rgba(2, 79, 158, 0.1);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #635551;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f4f2f2;
  margin-bottom: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 500;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #635551;
  content: "/";
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #7D2280;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #fb8110;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 0 0 30px 0;
  position: relative;
  z-index: 3;
}

.why-us .content {
  padding: 30px;
  background: #7D2280;
  border-radius: 4px;
  color: #fff;
}

.why-us .content h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

.why-us .content p {
  margin-bottom: 30px;
  text-align: justify;
  font-size: 0.7em;
}

.why-us .content .more-btn {
  display: inline-block;
  background: #fb8110;
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.why-us .content .more-btn i {
  font-size: 14px;
}

iframe {
  width: 100% !important;
  height: 450px !important;
  overflow: hidden !important;
}
.iFrme {
  max-width: 100%;
  height: 300px !important;
}
.ifrmae2 {
  width: 100%;
  height: 430px !important;
}
.why-us .content .more-btn:hover {
  color: #7D2280;
  background: #fff;
}

.why-us .icon-boxes .icon-box {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 0px 15px;
  width: 100%;
}

.why-us .icon-boxes .icon-box i {
  font-size: 40px;
  color: #ff5821;
  margin-bottom: 30px;
}
.font-weight-bold {
  font-weight: bold;
  color: #7D2280;
}
.arrowright {
  font-size: 15px !important;
  color: #fff !important;
  border-radius: 50%;
  background: #7D2280 !important;
  padding: 15px;
}
.arrowright:hover {
  font-size: 15px !important;
  color: #fff !important;
  border-radius: 50%;
  background: #ff5821 !important;
  padding: 15px;
}
.knowmr {
  border-radius: 30px;
  padding: 5px 20px;
  border: 1px solid #7D2280;
  margin-top: 2em;
}

.why-us .icon-boxes .icon-box h4 {
  font-size: 2vw;
  font-weight: 700;
  margin: 0 0 30px 0;
}

.why-us .icon-boxes .icon-box p {
  font-size: 15px;
  color: #716f6f;
}
.minht {
  min-height: 50px;
}

.about .icon-boxes h4 {
  font-size: 18px;
  color: #4b7dab;
  margin-bottom: 15px;
}

.about .icon-boxes h3 {
  font-size: 28px;
  font-weight: 700;
  color: #2c4964;
  margin-bottom: 15px;
}

.about .icon-box {
  margin-top: 40px;
}

.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #8dc2f1;
  border-radius: 50px;
  transition: 0.5s;
}

.about .icon-box .icon i {
  color: #1977cc;
  font-size: 32px;
}

.about .icon-box:hover .icon {
  background: #1977cc;
  border-color: #1977cc;
}

.about .icon-box:hover .icon i {
  color: #fff;
}

.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}

.about .icon-box .title a {
  color: #343a40;
  transition: 0.3s;
}
.fa-arrow-right:before {
  content: "\f061";
  color: #fff;
}
.fa-user-md:before {
  color: #fff;
}

.fa-hospital:before {
  color: #fff;
}

.fa-award:before {
  color: #fff;
}
.fa-flask::before {
  color: #fff;
}
.about .icon-box .title a:hover {
  color: #1977cc;
}

.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 13px;
  text-align: justify;
}

.about .play-btn {
  width: 70px;
  height: 70px;
  background: radial-gradient(#1977cc 50%, rgba(25, 119, 204, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(25% - 47px);
  top: calc(60% - 47px);
  overflow: hidden;
}

.about .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(25, 119, 204, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:hover::after {
  border-left: 15px solid #1977cc;
  transform: scale(20);
}

.about .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  background: #f1f7fd;
  padding: 70px 0 60px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: #fff;
}

.counts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  background: #1977cc;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #082744;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #7D2280;
  overflow: hidden;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}
.services .icon:hover {
  color: #fb8110;
}

.services .icon i {
  font-size: 36px;
  line-height: 0;
}

.services .icon-box:hover .icon {
  box-shadow: 0px 0 25px rgba(63, 187, 192, 0.3);
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444444;
  transition: 0.3s;
}

.services .title a:hover {
  color: #fb8110;
}

.services .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #7D2280;
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Appointments
--------------------------------------------------------------*/
.appointment .php-email-form {
  width: 100%;
}

.appointment .php-email-form .form-group {
  padding-bottom: 8px;
}

.appointment .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.appointment .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.appointment .php-email-form .error-message br + br {
  margin-top: 25px;
}

.appointment .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.appointment .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.appointment .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.appointment .php-email-form input,
.appointment .php-email-form textarea,
.appointment .php-email-form select {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  padding: 10px !important;
}

.appointment .php-email-form input:focus,
.appointment .php-email-form textarea:focus,
.appointment .php-email-form select:focus {
  border-color: #1977cc;
}

.appointment .php-email-form input,
.appointment .php-email-form select {
  height: 44px;
}

.appointment .php-email-form textarea {
  padding: 10px 12px;
}

.appointment .php-email-form button[type="submit"] {
  background: #7D2280;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.appointment .php-email-form button[type="submit"]:hover {
  background: #1c84e3;
}

/*--------------------------------------------------------------
# Departments
--------------------------------------------------------------*/
.departments {
  overflow: hidden;
}
.barsfa {
  font-size: 20px;
  margin-top: 5px;
  display: none;
}
.departments .nav-tabs {
  border: 0;
}

.departments .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #2c4964;
  border-radius: 0;
  border-right: 2px solid #ebf1f6;
  font-weight: 600;
  font-size: 15px;
}

.departments .nav-link:hover {
  color: #fb8110;
}

.departments .nav-link.active {
  color: #fb8110;
  border-color: #1977cc;
}

.departments .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.departments .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2c4964;
}

.departments .details p {
  color: #777777;
}

.departments .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .departments .nav-link {
    border: 0;
    padding: 15px;
  }

  .departments .nav-link.active {
    color: #fff;
    background: #1977cc;
  }
}

/*--------------------------------------------------------------
# Doctors
--------------------------------------------------------------*/
.doctors {
  background: #fff;
}

.doctors .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
  padding: 30px;
  border-radius: 10px;
}

.doctors .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}

.doctors .member .pic img {
  transition: ease-in-out 0.3s;
}

.doctors .member:hover img {
  transform: scale(1.1);
}

.doctors .member .member-info {
  padding-left: 30px;
}

.doctors .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #2c4964;
}

.doctors .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.doctors .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #b2c8dd;
  bottom: 0;
  left: 0;
}

.doctors .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.doctors .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.doctors .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #a0bcd5;
}

.doctors .member .social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}

.doctors .member .social a:hover {
  background: #1977cc;
}

.doctors .member .social a + a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #76b5ee;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #1977cc;
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 30px 15px;
  min-height: 200px;
  box-shadow: 0px 2px 12px rgba(44, 73, 100, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 10px;
  border: 6px solid #fff;
  position: absolute;
  left: -45px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #badaf7;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #1977cc;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1977cc;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
  min-height: 230px;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #1977cc;
  float: left;
  width: 44px;
  height: 44px;
  background: #d6e9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2c4964;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #4b7dab;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #1977cc;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #1977cc;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #1c84e3;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #313030;
  font-size: 14px;
  background: #fff;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #7D2280;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 22px;
  margin: 0 0 30px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #656262;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #313030;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ffa587;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #656262;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #ff5821;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #313030;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  text-align: left;
  border: 1px solid #ffe2d9;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ff5821;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #ed3b00;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #313030;
}

#footer .social-links a {
  color: none !important;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  text-decoration: none;
}

.second a:hover {
  color: #fff !important;
}

.active-2 {
  color: #ff5821 !important;
}

.second span:hover {
  padding-bottom: 21px !important;
  border-bottom: 3px solid #ff5821 !important;
}

.breadcrumb > li + li:before {
  content: "" !important;
}

.breadcrumb {
  padding: 19px;
  font-size: 14px;
  color: #aaa !important;
  letter-spacing: 1px;
  border-radius: 5px !important;
}

.react-tabs__tab-list {
  border-bottom: 6px solid #7D2280 !important;
}
.text-justify {
  text-align: justify;
}
.p-6em {
  padding: 0em 4em 4em 4em;
}
.py-45em {
  padding: 4em 0;
}
.headerh4 {
  font-weight: 700;
  font-size: 30px;
  color: #343434 !important;
}

.react-tabs__tab--selected {
  background: #7D2280 !important;
  border: none !important;
  color: #fff !important;
  border-radius: 0 !important;
}
.mainTabs {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 4px solid #7D2280;
}
.card1 {
  box-shadow: 0px 1px 6px 0px #f5f5f5;
  background-color: #ffffff !important;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  padding: 20px;
}
.review_bg {
  background-color: #fafafa;
}
.card1 h2 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 20px;
}
.fa-caret-down:before {
  color: #fff;
}
.p__cls {
  font-size: 22px;
  font-weight: 700;
  color: #343434 !important;
}
.font700 {
  font-weight: 700;
  font-size: 20px;
}
.imgnow {
  background-color: #fafafa;
  padding: 40px;
}
.b_anchor {
  background: #7D2280 !important;
  border: none !important;
  color: #fff !important;
  padding: 1em 2em;
  border-radius: 0 !important;
}
.inactive {
  background: transparent !important;
  border: none !important;
  color: #000 !important;
  border-radius: 0 !important;
  padding: 1em 2em;
}

.my-4em {
  margin-top: 3em;
}
.tabflex {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  justify-content: flex-start;
  align-items: center;
  background-color: #e0f0ff;
  flex-wrap: wrap;
}
.tabflex a {
  padding: 1em 2em;
  text-align: center;
  color: #000;
  cursor: pointer;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 1em 2em !important;
  cursor: pointer;
}

.Title_header {
  font-weight: 700;
  font-size: 40px;
}

.fa,
i {
  font-size: 10px;
}

.fa-angle-double-right {
  color: #aaa !important;
}

.first {
  background-color: white !important;
}

.bread_a {
  text-decoration: none !important;
  color: #212529 !important;
  font-weight: 500;
  font-size: 12px;
}

.bread_a:focus,
.bread_a:active {
  outline: none !important;
  box-shadow: none !important;
}

.fa-caret-right,
.fa-angle-double-right {
  font-size: 13px !important;
  margin-left: 4px;
}

.fa-caret-right {
  vertical-align: middle;
}

.four ol {
  background-color: rgb(51, 0, 80) !important;
}

@media (max-width: 767px) {
  .breadcrumb {
    font-size: 10px;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }
  .fa {
    font-size: 9px !important;
  }

  .breadcrumb {
    letter-spacing: 1px !important;
  }
  .breadcrumb > * div {
    max-width: 60px;
  }
}

.stars {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.min335 {
  min-height: 335px;
}

.post {
  margin-bottom: 40px;
}
.post .post-img {
  display: block;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}
.post .post-img > img {
  width: 100%;
  min-height: 190px;
}
.post .post-meta {
  margin-top: 15px;
  margin-bottom: 15px;
}
.post .post-title {
  font-size: 18px;
  margin-bottom: 0px;
}
.post_a {
  font-weight: 600;
  color: #212631;
  text-decoration: none;
}
.post-meta .post-category {
  font-size: 13px;
  text-transform: uppercase;
  padding: 3px 10px;
  font-weight: 600;
  border-radius: 2px;
  margin-right: 15px;
  color: #fff;
  background-color: #212631;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
}
.post-meta .post-category.cat-1 {
  background-color: #ed3b00;
}
.min138 {
  min-height: 80px;
}

.accordion-container {
  margin: 0 0 10px;
}
.accordion-toggle {
  position: relative;
  display: block;
  padding: 5px;
  font-size: 1em;
  font-weight: 300;
  background: #999;
  color: #000;
  text-decoration: none;
  font-weight: 500;
}
.accordion-toggle.open,
.accordion-toggle:hover {
  background: #7D2280;
  color: #fff;
}
.accordion-toggle span.toggle-icon {
  position: absolute;
  top: 9px;
  right: 20px;
  font-size: 1.5em;
}
.box-shadow {
  text-shadow: 1px 2px 3px rgb(139, 136, 136);
}
.accordion-content {
  display: none;
  padding: 20px;
  overflow: auto;
}
.contactNow {
  padding-top: 35px !important;
  padding-bottom: 20px !important;
  margin-top: 15px !important;
  background-color: #73bdee;
  border-width: 1px;
  padding-left: 25px;
  padding-right: 25px;
  border-color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameDr {
  font-weight: 700;
  font-size: 34px;
}
.blueone {
  color: #7D2280;
}
.flexdear {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3em 10%;
}
.madampicsize {
  width: 300px;
  border-radius: 50%;
  border: 4px solid #d4d3d3;
}
.bgone {
  background-color: #fafafa;
}
.pmsdy-4 {
  padding: 1em 5em;
}
.starsize {
  font-size: 20px;
  color: gold !important;
}
.fontwave {
  font-size: 25px;
  margin: 0 5px;
}
.ratings {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.knowmore_cls {
  background-color: #ed3b00;
  color: #fff;
  padding: 1em 3em;
  border-radius: 30px;
}
.formSubmit {
  background-color: #ffe6cf;
  padding: 1em 2em;
  margin: 0;
  border: 1px solid #000;
  min-height: 447px;
}
.bookapp {
  background-color: #ed3b00;
  color: #fff;
  border-radius: 30px;
  width: 100%;
  padding: 1em 4em;
  margin: 0.5em;
}
.bookapp:hover {
  color: #fff !important;
}
.colorora {
  color: #ed3b00;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #7D2280 !important;
  border-color: #7D2280 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #000 !important;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1194px !important;
  }
}

.modal-dialog {
  top: 110px !important;
}

@media (min-width: 200px) and (max-width: 359px) {
  #hero {
    margin-bottom: 0px !important;
    height: 32vh !important;
  }
  .widthnow240 {
    width: 250px !important;
  }
  .contact_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .madampicsize {
    width: 198px;
    border-radius: 50%;
    border: 4px solid #d4d3d3;
  }
  .pmsdy-4 {
    padding: 1em 0.2em;
  }
  .flexdear {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .container_view .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .logosize {
    width: 150px;
  }
  #hero h2 {
    color: #eee;
    margin-bottom: 10px;
    font-size: 8px;
  }
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
  }
}

@media (min-width: 200px) and (max-width: 360px) {
  .logo_Section img{
    width: 250px;
    height: 70px;
  }
  .callus h5{
    font-size: 17px !important;
  }
}



/* Media Query for Mobile Devices */
@media (min-width: 360px) and (max-width: 480px) {
.logo_Section img{
  width: 350px;
}
  .p-6em {
    padding: 0em 1em 1em 1em;
  }
  .imgnow {
    background-color: #fafafa;
    padding: 10px;
  }
  .logosize {
    width: 350px;
  }
  #hero h2 {
    color: #eee;
    margin-bottom: 10px;
    font-size: 10px;
  }
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #fff;
  }
  .classnumber {
    font-size: 10px !important;
    font-weight: bold;
    color: #000 !important;
  }
  .topspace {
    margin-top: 6em;
  }
  .bookapp {
    background-color: #ed3b00;
    color: #fff;
    border-radius: 30px;
    width: 100%;
    padding: 1em 0em;
    margin: 0.5em;
  }
  

  .barsfa {
    font-size: 20px;
    margin-top: 5px;
    display: blocke;
  }

  .flexdear {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .pmsdy-4 {
    padding: 1em 0em !important;
  }

  .contact_section {
    flex-direction: row;
  }
  .Title_header {
    font-weight: 700;
    font-size: 20px;
  }

  .container_view .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .topspace {
    position: relative;
    top: -135px;
  }
  #hero {
    width: 100%;
    height: 60vh;
    background: url("../src/assets/hero-bg1.jpg") center center;
    background-size: contain;
    position: relative;
    top: -9px;
    z-index: 1;
    background-repeat: no-repeat;
    padding: 0;
    margin-bottom: 0px;
  }

  .callus h5 {
    font-size: 0.5em;
  }
  .callus p {
    font-size: 0.7em;
  }
  .search_Section {
    margin: 0 2em;
  }
  .css-1vm0r4s-MuiPaper-root {
    width: 270px !important;
  }
  .mainTabs {
    display: flex;
    flex-direction: row;
    margin: 1em 0;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 4px solid #7D2280;
    flex-wrap: wrap;
  }
  .flexdear {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .flexdear {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
 
  .p-6em {
    padding: 0em 1em 1em 1em;
  }
  .logosize {
    width: 400px;
  }
  .imgnow {
    background-color: #fafafa;
    padding: 10px;
  }
  .classnumber {
    font-size: 10px !important;
    font-weight: bold;
    color: #000 !important;
  }
  .topspace {
    margin-top: 6em;
  }
  .bookapp {
    background-color: #ed3b00;
    color: #fff;
    border-radius: 30px;
    width: 100%;
    padding: 1em 0em;
    margin: 0.5em;
  }

  .barsfa {
    font-size: 20px;
    margin-top: 5px;
    display: blocke;
  }

  .flexdear {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .pmsdy-4 {
    padding: 1em 0em !important;
  }

  .contact_section {
    flex-direction: row;
  }
  .Title_header {
    font-weight: 700;
    font-size: 20px;
  }

  .container_view .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .topspace {
    position: relative;
    top: -135px;
  }
  #hero {
    width: 100%;
    height: 60vh;
    background: url("../src/assets/hero-bg1.jpg") center center;
    background-size: contain;
    position: relative;
    top: -9px;
    z-index: 1;
    background-repeat: no-repeat;
    padding: 0;
    margin-bottom: 0px;
  }

  .callus h5 {
    font-size: 0.5em;
  }
  .callus p {
    font-size: 0.7em;
  }
  .search_Section {
    margin: 0 2em;
  }
  .css-1vm0r4s-MuiPaper-root {
    width: 270px !important;
  }
  .mainTabs {
    display: flex;
    flex-direction: row;
    margin: 1em 0;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 4px solid #7D2280;
    flex-wrap: wrap;
  }
  .flexdear {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .flexdear {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3em 10%;
  }
  .container_view .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .pmsdy-4 {
    padding: 1em 0em !important;
  }
  #hero {
    width: 100%;
    height: 77vh;
    background: url("../src/assets/hero-bg1.jpg") center center;
    background-size: contain;
    position: relative;
    top: -9px;
    z-index: 1;
    background-repeat: no-repeat;
    padding: 0;
    margin-bottom: 0px;
  }
  .topspace {
    position: relative;
    top: -95px;
  }

  #hero h1 {
    font-size: 42px;
    line-height: 50px;
  }

  #hero h2 {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    color: #4b4949;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .contact_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
  }

  .logosize {
    width: 400px;
  }
  .container_view .headerSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  #hero h1 {
    margin: 0 0 10px 0;
    font-size: 41px !important;
    font-weight: 700;
    line-height: 52px !important;
    color: #fff;
  }
  #hero {
    width: 100%;
    height: 80vh;
    background: url("../src/assets/hero-bg1.jpg") center center;
    background-size: cover;
    position: relative;
    top: -9px;
    z-index: 1;
    padding: 0;
    margin-bottom: 20px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


.modal-backdrop {
  z-index: 100000 !important;
}

.modal {
  z-index: 100001 !important;
}

.contact_card{
  color:white;
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);}

  .contact_card1{
    color:white;
    background-image: linear-gradient(to top, #00c6fb 0%, #0b7e65 100%);}



.videoframe{
  height:277px !important
}
    